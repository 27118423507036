<template>
  <div class="brands">
    <NavHeader />
    <div class="content_container">
      <div class="banner">
        <!-- <img v-for='(item, index) in banner' :key='index' class="banner04" :src="$config.baseUrl + item.get_files.files_path" alt="" /> -->
          
        <el-carousel trigger="click" height="350px">
          <el-carousel-item v-for="item in bannerUrl" :key="item.id">
            <a :href=item.href target="_blank">
            <img class="banner04" :src=item.src alt="" />
            </a>
          </el-carousel-item>
        </el-carousel>

      </div>
      <div class="mat_box">
        <header class="title"></header>
        <main style="display: flex; margin: auto">
          <aside>
            <el-card class="mat_aside">
              <h1 @click="searchData.pcatid = 344, searchData.catid = null, getGoods()">
                <span :class="searchData.catid == null ? 'mat_aside_t2' : 'mat_aside_t1'" style="font-size: 22px;">{{ materialName }}</span>
                <img v-show="searchData.catid == null" src="../../assets/images/arrow1.gif" style="width: 20px;margin-bottom: -3px;">
              </h1>
              <div v-for="(item, index) in Category" :key="index">
                <div>
                  <h3 @click="searchData.catid = item.id, getGoods()">
                    <span :class="searchData.catid == item.id ? 'mat_aside_t2' : 'mat_aside_t1'" style="font-size: 18px;">{{ item.cate_name }}</span>
                    <img v-show="searchData.catid == item.id" src="../../assets/images/arrow1.gif" style="width: 20px;margin-bottom: -3px;">
                  </h3>
                  <div class="side_label">
                    <span v-for="(i, k) in item.cc" :key="k"
                      :class="searchData.catid == i.id ? 'mat_aside_span2' : 'mat_aside_span1'"
                      @click="searchData.catid = i.id, getGoods()">{{ i.cate_name }}</span>
                  </div>
                </div>
              </div>
            </el-card>
          </aside>
          <section class="mat_sec">
            <el-card class="card_box" v-for="(item, index) in goodList" :key="index">
              <div class="product_img">
                <img :src="$config.baseUrl + item.files_path" @click="
                  $util.toDetail(item.get_shop.shop_type, item.goods_id)
                  " />
              </div>
              <p class="item-name" @click="$util.toDetail(item.get_shop.shop_type, item.goods_id)">
                {{ item.goods_name }}
              </p>
              <div class="item-price" v-if="item.isonly == 1">
                <span>￥{{ JSON.parse(item.num_price)[0].price }} ~ ￥{{ JSON.parse(item.num_price)[JSON.parse(item.num_price).length-1].price }}</span>
              </div>
              <div class="item-num" v-if="item.isonly == 1">
                <span>起订量:{{ item.start_num }}</span>
              </div>
              <div class="item-quantity" v-if="item.isonly == 1">
                <span>库存:{{ item.quantity }}</span>
              </div>
              <div class="item-price" v-if="item.isonly == 2 && item.num_price">
                <span>￥{{ JSON.parse(item.num_price)[0].price[0].price }} 以下</span>
              </div>
              <div class="item-num" v-if="item.isonly == 2 && item.num_price">
                <span>起订量:{{ JSON.parse(item.num_price)[0].price[0].number }}</span>
              </div>
              <div class="item-quantity" v-if="item.isonly == 2 && item.num_price">
                <span>库存:{{ JSON.parse(item.num_price)[0].quantity }}</span>
              </div>
              <div class="shop-name" @click="$util.toShop(item.get_shop.shop_type, item.get_shop.shop_id)">
                <span style="color: #999"> {{ item.get_shop.shop_name }} </span>
                <i style="color: #fe6925">进店</i>
              </div>
            </el-card>
          </section>
        </main>
      </div>

      <el-pagination @current-change="currentChange" :current-page.sync="searchData.page"
        :page-size.sync="searchData.limit" :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import NavHeader from "@/components/layout/nav-header";

export default {
  data() {
    return {
      total: 0,
      banner: [],
      // searchData: {
      //   shop_type: 2,
      //   page: 1,
      //   limit: 15,
      // },
      //分类
      materialName: "",
      goodList: [],
      Category: [],
      searchData: {
        pstart: "",
        pend: "",
        brand_id: "",
        catid: null,
        pcatid: 344,
        page: 1,
        limit: 25,
        goodslabel: "",
        sort: "温度仪表",
      },
      
      // banner图轮播
      bannerUrl:[
      {id:2,src:require('../../assets/images/material_banner2.jpg'),href:'http://test.861718.com/2024/#/home'},
      {id:3,src:require('../../assets/images/material_banner3.jpg'),href:'http://new.861718.com/2024/#/home'},
      {id:1,src:require('../../assets/images/material_banner1.jpeg'),href:'#'},
      ],
    };
  },
  computed: {
    member() {
      return this.$store.state.member;
    },
  },
  mounted() {
    this.$get("/home/goodsCategory").then((res) => {
      this.materialName = res[6].cate_name
      this.Category = res[6].cc;
    });
    this.getGoods();
    this.getBanner()
  },
  methods: {
    getBanner() {
      this.$get("home/ad", { pid: 541 }).then(res => {
        this.banner = res || [];
      });
    },
    getGoods() {
      this.$get("/home/goods", this.searchData).then((res) => {
        this.goodList = res.items.data;
        this.total = res.items.total;
        console.log("goodList", this.goodList);
      });
    },
    currentChange() {
      this.supplyData = [];
      this.$get("/home/goods", this.searchData).then((res) => {
        this.goodList = res.items.data;
        this.total = res.items.total;
      });
    },
  },
  components: {
    NavHeader,
  },
};
</script>
<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #dbd9d9;
  border-radius: 3px;
}
::v-deep .el-card__body{
  padding: 10px;
}
.banner {
  width: 100%;
  height: 350px;

  img {
    width: 100%;
    height: 100%;
  }
}

.chanping1:hover {
  background: #ff864f !important;
}

.card_box:hover {
  transform: scale(1.03);
}

.mat_aside_t1 {
  border-bottom: 3px solid #EBEBEB;
  padding: 6px; 
  color: #111;
}

.mat_aside_t2 {
  border-bottom: 3px solid #fd5d14;
  padding: 6px;
  color: #000;
  // box-shadow: 0 5px 0 #EBEBEB, 0 6px 0 #999;
}

.mat_box {
  width: 1200px;
  margin: 8px auto 20px;

  .title {
    height: 45px;
    text-align: center;
    color: #fff;
    line-height: 45px;
    font-size: 22px;
    width: 100%;
    background-image: linear-gradient(to right, #fe6925, #f37b45);
    letter-spacing: 1.5px;
  }

  .mat_aside {
    width: 260px;
    // height: 700px;
    background: #ebebeb;

    h1 {
      font-size: 22px;
      color: #000;
      margin-bottom: 10px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h3 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      cursor: pointer;
      margin-bottom: 4px;
    }


    .side_label {
      display: flex;
      flex-wrap: wrap;

      .mat_aside_span1 {
        font-size: 14px;
        cursor: pointer;
        padding: 4px 14px;
        // background: #fe6925;
        border: 1px solid #fe6925;
        color: #fe6925;
        border-radius: 4px;
        margin: 6px;
        transition: all 0.2s;
      }

      .mat_aside_span2 {
        font-size: 14px;
        cursor: pointer;
        padding: 4px 14px;
        // background: #fe6925;
        border: 1px solid #fe6925;
        background: #fe6925;
        color: #fff;
        border-radius: 4px;
        margin: 6px;
        transition: all 0.2s;
      }

      span:hover {
        background: #fe6925;
        color: #fff;
      }
    }
  }

  .mat_sec {
    width: 940px;
    // height: 900px;
    background: #f5f5f5;
    display: flex;
    align-content: flex-start;
    padding-left: 12px;
    padding-top: 4px;
    flex-wrap: wrap;

    // overflow-y: auto;
    .card_box {
      width: 220px;
      cursor: pointer;
      height: 360px;
      margin: 10px 6px;
      text-align: center;

      .product_img {
        width: 170px;
        height: 170px;
        text-align: center;
        margin: auto;

        img {
          max-width: 100%;
          max-height: 100%
        }
      }

      .item-name {
        height: 46px;
        margin-top: 6px;
        font-size: 16px;
        overflow: hidden;
        color: #666;
      }

      .item-price {
        margin-top: 4px;
        span {
          color: #ff0000;
          height: 40px;
          font-size: 16px
        }
      }
      .item-num{
        margin-top: 4px;
        span{
          color: #f37b45;
          font-size: 15px;
        }
        
      }
      .item-quantity{
        margin-top: 4px;
        span{
          font-size: 15px;
          color: #999;
        }
      }

      .shop-name {
        display: flex;
        justify-content: space-between;
        height: 20px;
        margin-top: 24px;
      }
    }
  }
}
</style>
   